import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="organisation-analysis"]'
  );
  console.log('query for organisation-analysis elements', elements);
  if (elements) {
    const { default: OrganisationAnalysis } = await import(
      /* webpackChunkName: "organisation-analysis" */ './organisation-analysis'
    );

    forEach(elements, (index, element) => new OrganisationAnalysis(element));
  }
}
