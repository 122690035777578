export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="teaser-hero"]');

  const initTeaserHero = async () => {
    const { default: TeaserHero } = await import(
      /* webpackChunkName: "teaser-hero" */ './teaser-hero'
    );
    elements.forEach((element) => new TeaserHero(element));
  };

  if (elements.length) initTeaserHero();
}

init();
