const initForms = () => {
  const forms = document.querySelectorAll('[data-js-module="form"]');

  const initForm = async () => {
    const { default: Form } = await import(
      /* webpackChunkName: "form" */ './form'
    );
    forms.forEach((form) => new Form(form));
  };

  if (forms.length) initForm();
};

const initMultistepForms = () => {
  const multistepElements = document.querySelectorAll(
    '[data-js-module="multistep-form"]'
  );

  const initMultistep = async () => {
    const { default: FormApp } = await import(
      /* webpackChunkName: "multistep-form" */ './multistep-form.jsx'
    );
    multistepElements.forEach((element) => new FormApp(element));
  };

  if (multistepElements.length) initMultistep();
};

export default async function init() {
  initForms();
  initMultistepForms();
}

init();
