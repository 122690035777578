export default async function init() {
  const infoPoints = document.querySelectorAll('[data-js-module="info-point"]');

  const initInfoPoint = async () => {
    const { default: InfoPoint } = await import(
      /* webpackChunkName: "info-point" */ './infoPoint'
    );
    infoPoints.forEach((infoPoint) => new InfoPoint(infoPoint));
  };

  if (infoPoints.length) initInfoPoint();
}

init();
