export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="link-list"]');

  const initLinkList = async () => {
    const { default: LinkList } = await import(
      /* webpackChunkName: "link-list" */ './link-list'
    );
    elements.forEach((element) => new LinkList(element));
  };

  if (elements.length) initLinkList();
}

init();
