export default async function init() {
  const internalEvaluation = document.querySelectorAll(
    '[data-js-module="internalEvaluation"]'
  );

  const initForm = async () => {
    const { default: InternalEvaluation } = await import(
      /* webpackChunkName: "internalEvaluation" */ './internalEvaluation.js'
    );
    internalEvaluation.forEach((form) => new InternalEvaluation(form));
  };

  if (internalEvaluation.length) initForm();
}

init();
