import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="sticky"]');

  const { default: Sticky } = await import(
    /* webpackChunkName: "sticky" */ './sticky'
  );

  forEach(elements, (index, element) => new Sticky(element));
}

init();
