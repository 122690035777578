export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="favorites-list"]'
  );

  const { default: FavoritesListApp } = await import(
    /* webpackChunkName: "favorites-list" */ './favorites-list.jsx'
  );
  elements.forEach((element) => new FavoritesListApp(element));
}

init();
