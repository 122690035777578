export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="input-group"]');

  const initInputGroup = async () => {
    const { default: InputGroup } = await import(
      /* webpackChunkName: "input-group" */ './input-group.jsx'
    );
    elements.forEach((element) => new InputGroup(element));
  };

  if (elements.length) initInputGroup();
}

init();
