export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="video"]');

  const initVideo = async () => {
    const { default: Video } = await import(
      /* webpackChunkName: "video" */ './video'
    );
    elements.forEach((element) => new Video(element));
  };

  if (elements.length) initVideo();
}

init();
