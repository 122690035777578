import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="favorite-btn"]');

  const { default: FavoriteBtn } = await import(
    /* webpackChunkName: "favorite-btn" */ './favorite-btn'
  );

  forEach(elements, (index, element) => new FavoriteBtn(element));
}

init();
