import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="favorites-link"]'
  );

  const { default: FavoritesLink } = await import(
    /* webpackChunkName: "favorites-link" */ './favorites-link'
  );

  forEach(elements, (index, element) => new FavoritesLink(element));
}

init();
