export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="steps"]');

  const initSteps = async () => {
    const { default: Numbers } = await import(
      /* webpackChunkName: "steps" */ './steps'
    );
    elements.forEach((element) => new Numbers(element));
  };

  if (elements.length) initSteps();
}

init();
