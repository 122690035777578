export default async function init() {
  const teaserContainers = document.querySelectorAll(
    '[data-js-module="teaser-container"]'
  );

  const initTeaserContainer = async () => {
    const { default: TeaserContainer } = await import(
      /* webpackChunkName: "teaser-container" */ './teaser-container'
    );
    teaserContainers.forEach(
      (teaserContainer) => new TeaserContainer(teaserContainer)
    );
  };

  if (teaserContainers.length) initTeaserContainer();
}

init();
