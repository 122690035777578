export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="search"]');

  const initSearch = async () => {
    const { default: SearchApp } = await import(
      /* webpackChunkName: "search" */ './search.jsx'
    );
    elements.forEach((element) => new SearchApp(element));
  };

  if (elements.length) initSearch();
}

init();
