export default async function init() {
  // const elements = document.querySelectorAll('[data-js-module="block-media"]');
  // const initBlockMedia = async () => {
  //   const { default: BlockMedia } = await import(
  //     /* webpackChunkName: "block-media" */ './block-media'
  //   );
  //   elements.forEach((element) => new BlockMedia(element));
  // };
  // if (elements.length) initBlockMedia();
}

init();
