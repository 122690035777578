import forEach from '../../../javascripts/dom/forEach';

export default () => {
  const elements = document.querySelectorAll('[data-js-module="password"]');

  const init = async () => {
    const { default: Password } = await import(
      /* webpackChunkName: "password" */ './password.js'
    );

    forEach(elements, (index, password) => new Password(password));
  };

  if (elements.length) init();
};
