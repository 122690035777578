export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="table"]');

  const initTable = async () => {
    const { default: Table } = await import(
      /* webpackChunkName: "table" */ './table'
    );
    elements.forEach((element) => new Table(element));
  };

  if (elements.length) initTable();
}

init();
