export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="file-upload"]');

  const initFileUpload = async () => {
    const { default: FileUpload } = await import(
      /* webpackChunkName: "file-upload" */ './file-upload'
    );
    elements.forEach((element) => new FileUpload(element));
  };

  if (elements.length) initFileUpload();
}

init();
