import forEach from '../../../javascripts/dom/forEach';

export default () => {
  const elements = document.querySelectorAll('[data-js-module="sortable"]');

  const init = async () => {
    const { default: Sortable } = await import(
      /* webpackChunkName: "sortable" */ './sortable.js'
    );

    forEach(elements, (index, password) => new Sortable(password));
  };

  if (elements.length) init();
};
