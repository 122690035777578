export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="location"]');

  const initLocation = async () => {
    const { default: LocationApp } = await import(
      /* webpackChunkName: "location" */ './location.jsx'
    );
    elements.forEach((element) => new LocationApp(element));
  };

  if (elements.length) initLocation();
}

init();
