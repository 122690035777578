export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="questionaire"]');

  const { default: QuestionaireApp } = await import(
    /* webpackChunkName: "questionaire" */ './questionaire.jsx'
  );
  elements.forEach((element) => new QuestionaireApp(element));
}

init();
