import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="range"]');

  const { default: Range } = await import(
    /* webpackChunkName: "range" */ './range'
  );

  forEach(elements, (index, element) => new Range(element));
}

init();
