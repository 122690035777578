export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="numbers"]');

  const initNumbers = async () => {
    const { default: Numbers } = await import(
      /* webpackChunkName: "numbers" */ './numbers'
    );
    elements.forEach((element) => new Numbers(element));
  };

  if (elements.length) initNumbers();
}

init();
