/**
 *  Add custom scroll to anchor links on page load
 * - to add navigation height so scrollTop
 * @returns {void}
 */
const checkURLHash = () => {
  const { hash } = window.location;

  if (hash) {
    const linkTarget = document.getElementById(hash.slice(1));

    // if target exists
    if (linkTarget) {
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      });
      setTimeout(() => {
        window.scrollTo({
          top: linkTarget.offsetTop,
          left: 0,
          behavior: 'smooth'
        });
      }, 200);
    }
  }
};

/**
 *  Add custom scroll to anchor links on click
 * - to add navigation height so scrollTop
 * @returns {void}
 */
const checkAnchorLinks = () => {
  const anchorLinks = Array.from(document.getElementsByTagName('a')).filter(
    (element) => {
      const href = element.getAttribute('href');
      return href?.startsWith('#');
    }
  );
  anchorLinks.map((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      const linkHref = e.target.closest('a').getAttribute('href');
      const linkTarget = document.getElementById(linkHref.slice(1));

      window.scrollTo({
        top: linkTarget.offsetTop,
        left: 0,
        behavior: 'smooth'
      });
    });
  });
};

/**
 * Custom anchor handling
 * - Checks for anchors in urls
 * - Adds custom scroll to anchor links
 * @returns {void}
 */
export default () => {
  checkURLHash();
  checkAnchorLinks();
};
