import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="chart"]');
  if (elements) {
    const { default: Chart } = await import(
      /* webpackChunkName: "chart" */ './chart'
    );

    forEach(elements, (index, element) => new Chart(element));
  }
}
