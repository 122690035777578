export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="intro"]');

  const initIntro = async () => {
    const { default: Intro } = await import(
      /* webpackChunkName: "intro" */ './intro'
    );
    elements.forEach((element) => new Intro(element));
  };

  if (elements.length) initIntro();
}

init();
