import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const loops = document.querySelectorAll('[data-js-module="loop"]');

  if (loops) {
    const { default: Loops } = await import(
      /* webpackChunkName: "loop" */ './loop'
    );

    forEach(loops, (index, element) => new Loops(element));
  }
}

init();
