export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="list"]');

  const initList = async () => {
    const { default: List } = await import(
      /* webpackChunkName: "list" */ './list'
    );
    elements.forEach((element) => new List(element));
  };

  if (elements.length) initList();
}

init();
