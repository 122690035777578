export default async function init(forceShow = false) {
  const debugGrids = document.querySelectorAll('[data-js-module="debug-grid"]');
  const initDebugGrid = async () => {
    const { default: DebugGrid } = await import(
      /* webpackChunkName: "debug-grid" */
      './debug-grid'
    );

    debugGrids.forEach((debugGrid) => {
      // TODO clean this up
      if (!debugGrid.hasAttribute('data-js-module-initialized')) {
        return new DebugGrid(debugGrid, {
          forceShow
        });
      }

      return null;
    });
  };

  if (debugGrids.length) initDebugGrid();
}

init();
