import forEach from '../../../javascripts/dom/forEach';

export default function init() {
  const lazyImages = document.querySelectorAll('[loading="lazy"]');
  // const image = document.createElement('img');

  const initLazyLoadingPolyfill = async () => {
    await import(/* webpackChunkName: "image--lazysizes" */ 'lazysizes');
  };

  /**
   * Native lazy loading
   * @see {@link https://web.dev/native-lazy-loading/}
   * @see {@link https://obj.fork.de/pages/viewpage.action?pageId=63274039}
   * @see {@link https://caniuse.com/loading-lazy-attr}
   * @returns {void}
   */
  function initNativeLazyLoading() {
    if ('loading' in HTMLImageElement.prototype) {
      forEach(lazyImages, (i, img) => {
        // eslint-disable-next-line no-param-reassign
        img.src = img.dataset.src;
      });
    } else {
      initLazyLoadingPolyfill();
    }
  }

  if (lazyImages.length) initNativeLazyLoading();
}
