export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="switch"]');

  const initSwitch = async () => {
    const { default: Switch } = await import(
      /* webpackChunkName: "switch" */ './switch'
    );
    elements.forEach((element) => new Switch(element));
  };

  if (elements.length) initSwitch();
}

init();
