export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="testimonials"]');

  const initTestimonials = async () => {
    const { default: Testimonials } = await import(
      /* webpackChunkName: "testimonials" */ './testimonials'
    );
    elements.forEach((element) => new Testimonials(element));
  };

  if (elements.length) initTestimonials();
}

init();
